<div class="barra">
      <img src="images/template/onda-2.svg" alt="Barra" width="100%" />
  </div>
  <div class="module module-product-best-sellers">
      <div class="module-title" *ngIf="data.mostrar_titulo">
          <div class="container">
              <h2 class="wow slideInUp" data-wow-delay="0.6s">{{data.titulo}}</h2>
              <h3 class="wow slideInLeft" data-wow-delay="0.5s">{{data.subtitulo}}</h3>
          </div>
      </div>
      <div class="module-content">
          <div class="container">
              <div class="content">
                  <div class="products slider-items" #slider>
                      <div class="slider-item" *ngFor="let p of data.produtos">
                          <produto-mais-vendido-item [data]="p"></produto-mais-vendido-item>
                      </div>
                  </div>
                  <div class="navigate-bottom">
                      <div class="navigate-item prev" (click)="prev()">
                          <img width="30px" height="30px" src="images/icons/prev.svg" alt="Prev" />
                      </div>
                      <div class="navigate-item next" (click)="next()">
                          <img width="30px" height="30px" src="images/icons/next.svg" alt="Next" />
                      </div> 
                  </div>
              </div>
          </div>
      </div>
  </div>
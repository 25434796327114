<div class="module module-categories">
    <div class="module-title">
        <div class="container">
            <h2>{{data.titulo}}</h2>
            <h3 *ngIf="data.mostrar_subtitulo">{{data.subtitulo}}</h3>
        </div>
    </div>
    <div class="module-content">
        <div class="container">
            <div class="categories slider-items" #slider>
                <div class="slider-item" *ngFor="let d of data.categorias">
                    <produto-categoria-item [data]="d"></produto-categoria-item>
                </div> 
            </div>
            <div class="navigate-item prev" (click)="prev()">
                <img width="30px" height="30px" src="images/icons/prev.svg" alt="Prev" />
                <div class="bg"></div>
            </div>
            <div class="navigate-item next" (click)="next()">
                <img width="30px" height="30px" src="images/icons/next.svg" alt="Next" />
                <div class="bg"></div>
            </div> 
        </div>
    </div>
</div>
  
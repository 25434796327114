import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProdutoItemComponent } from 'src/app/components/produto/produto-item/produto-item.component';
import { ProdutoItemTwoComponent } from 'src/app/components/produto/produto-item-two/produto-item-two.component';
import { PipeModule } from '../../pipe/pipe.module';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import { ProdutoItemValorModule } from '../produto-item-valor/produto-item-valor.module';
import { ProdutoCategoriaItemComponent } from 'src/app/components/produto/produto-categoria-item/produto-categoria-item.component';
import { ProdutoGaleriaComponent } from 'src/app/components/produto/produto-galeria/produto-galeria.component';
import { BtnCartAddModule } from '../../carrinho/btn-cart-add/btn-cart-add.module';
import { ProdutoItemSliderTwoComponent } from 'src/app/components/produto/produto-item-slider-two/produto-item-slider-two.component';
import { ProdutoIntencaoModalModule } from '../produto-intencao-modal/produto-intencao-modal.module';
import { ProdutoMaisVendidoComponent } from 'src/app/modulos/produto-mais-vendido/produto-mais-vendido.component';
import { ProdutoMaisVendidoItemComponent } from 'src/app/components/produto/produto-mais-vendido-item/produto-mais-vendido-item.component';

@NgModule({
  declarations: [
    ProdutoItemComponent,
    ProdutoItemTwoComponent,
    ProdutoCategoriaItemComponent,
    ProdutoGaleriaComponent,
    ProdutoItemSliderTwoComponent,
    ProdutoMaisVendidoItemComponent
  ],
  exports: [   
    ProdutoItemTwoComponent,
    ProdutoItemComponent,
    ProdutoCategoriaItemComponent,
    ProdutoGaleriaComponent,
    ProdutoItemSliderTwoComponent,
    ProdutoMaisVendidoItemComponent
  ],
  imports: [
    ProdutoItemValorModule,
    LazyLoadImageModule,
    PipeModule,   
    CommonModule,
    BtnCartAddModule
  ]
})
export class ProdutoPartialModule { }

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GtagService } from 'src/app/services/gtag.service';

@Component({
  selector: 'produto-mais-vendido-item',
  templateUrl: './produto-mais-vendido-item.component.html',
  styleUrls: ['./produto-mais-vendido-item.component.scss']
})
export class ProdutoMaisVendidoItemComponent implements OnInit {

  @Input("data") produto:any                  = {};   
  @Input("isLazy") isLazy:any                 = false;    
  @Input("categoria") categoria:any           = null; 
  @Input("index") index:any                   = 0; 
  @Input("nome_lista") nome_lista:any         = "Página de Serviços";
  @Input("classCustom") classCustom:any       = "";
  @Input("apelidoDestino") apelidoDestino     = null;   
  @Input("apelidoCategoria") apelidoCategoria = null; 
  @Input("maxText") maxText                  = "60";
  public link                                 = null;   
  public defaultImage                         = "/images/produto/sem-foto/default.jpg";
  public loader                               = false;  
  public estoque:any = {
    total_in: 0,
    total_out: 0,
    quantidade_atual: 0,
    total_in_cart: 0
  }

  constructor(
    public router: Router,
    private gtag: GtagService
  ){}
    /**
   * 
   * Seta o link para o detalhe do produto
   * 
   */
  setLink(){
   
    if(this.categoria ==  null){
      this.link = "/produtos/"+this.produto.categoria_apelido+"/"+this.produto.apelido;
    }else{
      this.link = "/produtos/"+this.categoria.apelido+"/"+this.produto.apelido;
    }  

  }
  /**
   * 
   * On click
   * 
   */
  onClick(){

    try{
      
      this.router.navigateByUrl(this.link);

      this.gtag.selectProduct({
        id: this.produto.id,
        nome: this.produto.nome,
        categoria: this.produto.categoria_titulo,
        list_name: this.nome_lista,
        list_position: this.index+1 
      });

    }catch(e){
      
    }
  
  }
  /**
   * 
   * Formatar foto
   * 
   */
  formatarFoto(){

    this.produto.foto = this.produto.foto != null ? this.produto.foto.replace(".png",".jpg") : null;  

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit(): void {
    this.setLink();
    this.formatarFoto();
  }
}

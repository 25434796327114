<div *ngFor="let m of modulos" [ngSwitch]="m.tag">
  <div *ngSwitchCase="'logo'">  
    <modulo-logo [data]="m" [classCustom]="classCustom" [template]="template"></modulo-logo>  
  </div>
  <div *ngSwitchCase="'whatsapp'">   
    <modulo-whatsapp [carrinho]="carrinho" [data]="m" [classCustom]="classCustom" [template]="template"></modulo-whatsapp>  
  </div>
  <div *ngSwitchCase="'rede-social'">   
    <modulo-rede-social [data]="m" [classCustom]="classCustom" [template]="template"></modulo-rede-social>  
  </div>
  <div *ngSwitchCase="'endereco'">     
    <modulo-endereco [data]="m" [classCustom]="classCustom" [template]="template"></modulo-endereco>  
  </div>
  <div *ngSwitchCase="'banner-slider'"> 
    <modulo-banner-slider-two [data]="m" [classCustom]="classCustom" [template]="template"></modulo-banner-slider-two> 
  </div>
  <div *ngSwitchCase="'newsletter'"> 
    <modulo-newsletter [data]="m" [classCustom]="classCustom" [template]="template"></modulo-newsletter> 
  </div>
  <div *ngSwitchCase="'contato'">   
    <modulo-contato [data]="m" [classCustom]="classCustom" [template]="template"></modulo-contato> 
  </div> 
  <div *ngSwitchCase="'fale-conosco'">  
    <modulo-fale-conosco [data]="m" [classCustom]="classCustom" [template]="template"></modulo-fale-conosco> 
  </div>
  <div *ngSwitchCase="'produto-categorias'">          
    <modulo-produto-categorias [data]="m" [template]="template" [classCustom]="classCustom"></modulo-produto-categorias> 
  </div>
  <div *ngSwitchCase="'produto-destaque-1'">          
    <modulo-produto-destaque-one [data]="m" [template]="template" [classCustom]="classCustom"></modulo-produto-destaque-one> 
  </div>
  <div *ngSwitchCase="'produto-lancamento'">   
    <modulo-produto-lancamento [data]="m" [template]="template" [classCustom]="classCustom"></modulo-produto-lancamento> 
  </div>
  <div *ngSwitchCase="'produto-mais-vendido'"> 
    <modulo-produto-mais-vendido [data]="m" [template]="template" [classCustom]="classCustom"></modulo-produto-mais-vendido> 
  </div>
  <div *ngSwitchCase="'modulo-lista'">          
    <modulo-lista [data]="m" [template]="template" [classCustom]="classCustom"></modulo-lista> 
  </div>
  <div *ngSwitchCase="'atendimento-personalizado'">       
    <modulo-atendimento-personalizado [data]="m" [template]="template" [classCustom]="classCustom"></modulo-atendimento-personalizado> 
  </div>
  <div *ngSwitchCase="'produto-promocao'"> 
    <modulo-produto-promocao [data]="m" [template]="template" [classCustom]="classCustom"></modulo-produto-promocao> 
  </div>
</div>
  
  
  
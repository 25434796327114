import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { GtagService } from 'src/app/services/gtag.service';
import { Router } from '@angular/router';

@Component({
  selector: 'produto-item',
  templateUrl: './produto-item.component.html',
  styleUrls: ['./produto-item.component.scss']
})
export class ProdutoItemComponent implements OnInit, OnDestroy {

  @Input("data") produto:any                  = {};   
  @Input("isLazy") isLazy:any                 = false;    
  @Input("categoria") categoria:any           = null; 
  @Input("index") index:any                   = 0; 
  @Input("nome_lista") nome_lista:any         = "Página de Serviços";
  @Input("classCustom") classCustom:any       = "";
  @Input("apelidoDestino") apelidoDestino     = null;   
  @Input("apelidoCategoria") apelidoCategoria = null; 
  @Input("maxText") maxText                  = "60";
  public link                                 = null;   
  public defaultImage                         = "/images/produto/sem-foto/default.jpg";
  public loader                               = false;  
  public estoque:any = {
    total_in: 0,
    total_out: 0,
    quantidade_atual: 0,
    total_in_cart: 0
  }

  constructor( 
    private gtag: GtagService,
    private route: Router 
  ){
    
    this.isLazy = this.isLazy === true || this.isLazy === "true" ? true : false;  
    
  } 
  
  /**
   * 
   * Click
   * 
   */
  _click(){

    this.route.navigateByUrl(this.link);
    return false;

  }
  /**
   * 
   * Seta o link para o detalhe do produto
   * 
   */
  setLink(){
   
    if(this.categoria ==  null){
      this.link = "/produtos/"+this.produto.categoria_apelido+"/"+this.produto.apelido;
    }else{
      this.link = "/produtos/"+this.categoria.apelido+"/"+this.produto.apelido;
    }  

  }
  /**
   * 
   * On click
   * 
   */
  onClick(){

    try{
      
      this.route.navigateByUrl(this.link);

      this.gtag.selectProduct({
        id: this.produto.id,
        nome: this.produto.nome,
        categoria: this.produto.categoria_titulo,
        list_name: this.nome_lista,
        list_position: this.index+1 
      });

      return false;

    }catch(e){
      
    }
  
  }
   /**
   * 
   * Formatar foto
   * 
   */
  formatarFoto(){

    this.produto.foto = this.produto.foto != null ? this.produto.foto.replace(".png",".jpg") : null;  

  }
  /**
   * 
   * Set estoque
   * 
   */
  setEstoque(){

    if(typeof(this.produto.estoque) != "undefined"){

      if(this.produto.estoque != null){
        this.estoque = this.produto.estoque;
      }

    }

  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){
    this.setLink();
    this.formatarFoto();
    this.setEstoque();
  }
  /**
   * 
   * On Destroy
   * 
   */
  ngOnDestroy(): void {
    
  }

}

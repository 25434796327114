<div class="modulo modulo-promocao">
      <div class="modulo-titulo" *ngIf="data.mostrar_titulo">
        <div class="container">
          <h2>{{data.titulo}}</h2>
          <h3 *ngIf="data.mostrar_subtitulo">{{data.subtitulo}}</h3>
        </div>  
      </div>  
      <div class="content">
        <div class="section">
          <div class="section-content">  
            <div class="container"> 
              <div class="produtos slider-itens" #slider>   
                <div class="slider-item item-servicos" *ngFor="let item of produtos"> 
                  <produto-item [data]="item"></produto-item>    
                </div>    
              </div>
              <div class="navigate-bottom">
                <div class="navigate-item prev" (click)="prev()">
                    <img width="30px" height="30px" src="images/icons/prev.svg" alt="Prev" />
                </div>
                <div class="navigate-item next" (click)="next()">
                    <img width="30px" height="30px" src="images/icons/next.svg" alt="Next" />
                </div> 
            </div>
            </div>
          </div>  
        </div>      
      </div>  
</div>
      
<div class="module module-products">
    <div class="module-title" *ngIf="data.mostrar_titulo">
        <div class="container">
            <h2 class="wow slideInUp" data-wow-delay="0.5s">{{data.titulo}}</h2>
            <h3 class="wow slideInDown" data-wow-delay="0.6s">{{data.subtitulo}}</h3>
        </div>    
    </div> 
    <div class="module-content">
        <div class="container"> 
            <div class="products slider-items" #slider>   
                <div class="slider-item" *ngFor="let p of data.produtos">
                    <produto-item [data]="p"></produto-item>
                </div>      
            </div>
            <div class="slide-navigate-item prev" (click)="prev()">
                <img width="30px" height="30px" src="images/icons/prev.svg" alt="Prev" />
            </div>
            <div class="slide-navigate-item next" (click)="next()">
                <img width="30px" height="30px" src="images/icons/next.svg" alt="Next" />
            </div>  
        </div>
    </div>
    <div class="center">
        <a routerLink="/produtos" title="Produtos" class="btn-two btn-icon wow fadeIn" data-wow-delay="0.2s">
            <span>Ver todos os produtos</span>
        </a> 
    </div>     
</div>    

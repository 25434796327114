<a href="javascript::void();" (click)="onClick()"> 
  <div class="product-item {{classCustom}}">
      <div class="capa" *ngIf="produto.foto != null">
        <a [href]="link" (click)="onClick()" itemprop="url" title="Saiba mais sobre: {{produto.nome}}"> 
          <img [src]='produto.foto' [alt]="produto.nome" width="100%" />
        </a>
      </div>
      <div class="capa" *ngIf="produto.foto == null">  
        <a [href]="link" (click)="onClick()" itemprop="url" title="Saiba mais sobre: {{produto.nome}}">  
          <img itemprop="image" src="/images/produto/sem-foto/default-capa.jpg" [alt]="produto.nome" width="263px" height="174px" />
        </a> 
      </div>
      <div class="content">
          <h4>{{produto.nome}}</h4>      
          <div class="footer">
              <div class="box">
                  <div class="content-valor" *ngIf="!produto.promocao">
                    <div class="valor" *ngIf="produto.valor > 0">
                      <span class="cifrao">R$ </span>
                      <strong>{{produto.valor | currency: " "}}</strong>
                    </div> 
                    <div class="valor" *ngIf="produto.valor == 0">
                      <span class="cifrao">R$ </span>
                      <strong>CONSULTE!</strong>
                    </div> 
                    <div class="installments" *ngIf="produto.mostrar_parcelas">
                      <span>Em até {{produto.parcelas}}x de {{(produto.valor/produto.parcelas) | currency: " R$ "}} sem juros</span>
                    </div>
                  </div> 
                  <div class="content-valor" *ngIf="produto.promocao">
                    <div class="valor-promocao" *ngIf="produto.valor > 0">
                      <span>De </span>
                      <strong>R$ {{produto.valor | currency: " "}}</strong>
                      <span>por:</span>
                    </div> 
                    <div class="valor" *ngIf="produto.valor > 0">
                      <span class="cifrao">R$ </span>
                      <strong>{{produto.promocao_valor | currency: " "}}</strong>
                    </div> 
                    <div class="valor" *ngIf="produto.valor == 0">
                      <span class="cifrao">R$ </span>
                      <strong>CONSULTE!</strong>
                    </div> 
                    <div class="installments" *ngIf="produto.mostrar_parcelas">
                      <span>Em até {{produto.parcelas}}x de {{(produto.promocao_valor/produto.parcelas) | currency: " R$ "}} sem juros</span>
                    </div>
                  </div> 
              </div> 
          </div> 
      </div>    
  </div>   
</a> 


  
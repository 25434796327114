import { Component, ElementRef } from '@angular/core';
import { AppService } from './services/app.service';
import { NavigationEnd, NavigationStart, Router,Event as NavigationEvent } from '@angular/router';
import { GtagService } from './services/gtag.service';
import { filter } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { DataService } from './services/data.service';
import { ModuloService } from './services/modulo.service';
import { ApiService } from './services/api.service';
import { UsuarioService } from './services/usuario.service';
import { CartService } from './services/cart.service';

declare var $:any;
declare var WOW:any;
declare var dataModulos:any;
declare var dataConfiguracao:any;
declare var dataCart:any;
declare var dataColecoes:any;
declare var dataParceiro:any;
declare var dataCategoriaMenu:any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [AppService,ApiService,UsuarioService,CartService]
})
export class AppComponent {
  
  public modulos       = null;
  public cart          = null;
  public configuracao  = null;
  public categoriaMenu = null;
  public colecoes      = null;
  public parceiro      = null;

  constructor(
    private app: AppService,
    private router: Router,
    private gtag: GtagService,
    @Inject(DOCUMENT) private dom,
    private platform: Platform,
    private dataService: DataService,
    private moduloService: ModuloService
  ){

    this.modulos       = JSON.parse(dataModulos);
    this.cart          = JSON.parse(dataCart);
    this.configuracao  = JSON.parse(dataConfiguracao);
    this.categoriaMenu = JSON.parse(dataCategoriaMenu); 
    this.parceiro      = JSON.parse(dataParceiro); 
    this.colecoes      = JSON.parse(dataColecoes); 
 
    this.dataService.setTotalCart(this.cart); 
    this.dataService.setConfiguracao(this.configuracao);
    this.dataService.setCategoriasMenu(this.categoriaMenu);
    this.dataService.setColecoes(this.colecoes);  
    
    if(dataParceiro != null && dataParceiro != "null" && dataParceiro != ""){
      this.dataService.setParceiro(this.parceiro);  
    }

    this.onEvents();
  
  }
  /**
   * 
   * Init WOW
   * 
   */
   initWOW(){

    if(this.platform.isBrowser){
      
      try{
          var wow = new WOW();

          wow.init();

          $('.wow').on('scrollSpy:exit', function() {
              $(this).css({
                'visibility': 'hidden',
                'animation-name': 'none'
              }).removeClass('animated');
              wow.addBox(this);
          }).scrollSpy();


      }catch(e){
        
      }
      
    }

  }
  /**
   * 
   * On Events
   * 
   */
  onEvents(){

    this.router.events.forEach((event: NavigationEvent) => {
      
      if(event instanceof NavigationStart){
        this.app.loadEnd();    
      }
      if(event instanceof NavigationEnd) {

        this.initWOW();
                
      }

    });
    this.app.emitChangePage.subscribe(response => {
      
    });
    this.app.setStatusServidorEmitter.subscribe(code => {
      
    });
    const navEndEvents = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    );
    navEndEvents.subscribe((event: NavigationEnd) => {
      
      this.updateCanonicalUrl(event.urlAfterRedirects);
      
      setTimeout(() => { 
        this.gtag.sendPagePath(event.urlAfterRedirects); 
      },1000);

    });  
    this.moduloService.getModulosEmitter.subscribe(d =>{
      this.moduloService.updateModulosEmitter.emit(this.modulos);
    });
    this.app.emitGetCategorias.subscribe(d =>{ 
      this.app.emitSetCategorias.emit(this.categoriaMenu);
    }); 


  }
  /***
   * 
   * Update canonical
   * 
   */
  updateCanonicalUrl(url:string){
    
    let host = "https://"+location.hostname;

    let head = this.dom.getElementsByTagName('head')[0];
    let element: HTMLLinkElement= this.dom.querySelector(`link[rel='canonical']`) || null
    
    if (element==null) {
      element= this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href',host+url); 
  }



}

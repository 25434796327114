import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [{ 
  path: '',     
  loadChildren: () => import('src/app/modules/home/home.module').then(m => m.HomeModule) 
},{ 
  path: 'quem-somos',   
  loadChildren: () => import('src/app/modules/sobre/sobre-module/sobre.module').then(m => m.SobreModule) 
},{
  path: 'perguntas-frequentes',   
  loadChildren: () => import('src/app/modules/pages/perguntas-frequentes-page/perguntas-frequentes-page.module').then(m => m.PerguntasFrequentesPageModule) 
},{ 
  path: 'produtos/colecao/:apelido_colecao',    
  loadChildren: () => import('src/app/modules/pages/produto/produtos-page/produtos-page.module').then(m => m.ProdutosPageModule) 
},{ 
  path: 'produtos/:apelido_categoria/:apelido_produto',   
  loadChildren: () => import('src/app/modules/pages/produto/produto-page/produto-page.module').then(m => m.ProdutoPageModule)
},{ 
  path: 'produtos/:apelido_categoria/:apelido_produto',   
  loadChildren: () => import('src/app/modules/pages/produto/produto-page/produto-page.module').then(m => m.ProdutoPageModule)
},{
  path: 'produtos',
  loadChildren: () => import('src/app/modules/pages/produto/produtos-page/produtos-page.module').then(m => m.ProdutosPageModule) 
},{ 
  path: 'produtos/:apelido_categoria',    
  loadChildren: () => import('src/app/modules/pages/produto/produtos-page/produtos-page.module').then(m => m.ProdutosPageModule) 
},{
  path: 'favoritos', 
  loadChildren: () => import('src/app/modules/pages/produto/favoritos-page/favoritos-page.module').then(m => m.FavoritosPageModule) 
},{
  path: 'contato',   
  loadChildren: () => import('src/app/modules/contato/contato.module').then(m => m.ContatoModule) 
},{
  path: 'politicas-de-privacidade',
  loadChildren: () => import('src/app/modules/politica/politica-privacidade/politica-privacidade.module').then(m => m.PoliticaPrivacidadeModule)
},{
  path: 'politicas-de-cancelamento', 
  loadChildren: () => import('src/app/modules/politica/politica-cancelamento/politica-cancelamento.module').then(m => m.PoliticaCancelamentoModule)
},{
  path: 'politicas-de-cookies', 
  loadChildren: () => import('src/app/modules/politica/politica-cookie/politica-cookie.module').then(m => m.PoliticaCookieModule)
},{
  path: 'politicas-de-troca',
  loadChildren: () => import('src/app/modules/pages/politicas-de-troca-page/politicas-de-troca-page.module').then(m => m.PoliticasDeTrocaPageModule)
},{   
  path: '**',
  pathMatch: 'full', 
  redirectTo: ''
}];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<div class="module-newsletter">
    <div class="content">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="box-title">
                        <h4 class="wow slideInUp" data-wow-delay="0.8s">{{data.titulo}}</h4>
                        <div class="linha-divisor"></div>
                        <h5 class="wow slideInDown" data-wow-delay="0.5s">{{data.subtitulo}}</h5>
                    </div>
                </div>
                <div class="col-md-6">  
                    <form class="form-custom" (submit)="send()">
                        <div class="content-input">  
                            <div class="item item-input wow fadeIn" data-wow-delay="0.3s">
                                <input type="text" [(ngModel)]="dataItem.nome" name="nome" placeholder="Informe seu nome" />
                            </div>    
                            <div class="item item-input wow fadeIn" data-wow-delay="0.3s">
                                <input type="text" [(ngModel)]="dataItem.email" name="email" placeholder="Informe seu email" />    
                            </div> 
                        </div>  
                        <div class="content-button">  
                            <button class="wow fadeIn" data-wow-delay="0.5s"> 
                                <span>Me cadastrar</span>
                            </button>
                        </div>   
                    </form> 
                </div>
            </div>   
        </div>
    </div>
</div>    
<loader [hidden]="!loader"></loader>

<div class="category-item" (click)="toRoute(link)">
    <div class="content">
        <img [src]="data.capa" [alt]="data.nome" width="100%" />
    </div>
    <div class="footer">
        <h3>{{data.nome}}</h3>
    </div>
    <div class="overlay"></div>
</div>

  

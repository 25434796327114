import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

declare var $:any;

@Component({
  selector: 'modulo-produto-mais-vendido',
  templateUrl: './produto-mais-vendido.component.html',
  styleUrls: ['./produto-mais-vendido.component.scss']
})
export class ProdutoMaisVendidoComponent implements OnInit {

  @Input("data") data:any = {}; 
  @Input("classCustom") classCustom = "";
  @Input("template") template       = null;  
  public produtos                   = []; 

  @ViewChild("slider") slider: any; 
  public products:any = [];
  public tabSelect = 0;
  public tabs:any = [];
  public width = 0;

  constructor(
    private router: Router
  ) { }


  /***
   * 
   * Inicializa o Slider
   * 
   */
  initSlider(){

    $(this.slider.nativeElement).slick({ 
      infinite: true,
      slidesToShow: 2,
      dots: false, 
      arrows: false,
      centerMode: false,
      autoplay: true,
      autoplaySpeed: 4000,
      centerPadding: '0',
      responsive: [{
          breakpoint: 1224,
          settings: {
            slidesToShow: 4,  
          }
      },{
          breakpoint: 1024,
          settings: {
          slidesToShow: 2,
        }
      },
      {
          breakpoint: 768,
          settings: {
              slidesToShow: 2,

          }
      },
      {
          breakpoint: 540,
          settings: {
            slidesToShow: 1,
            centerPadding: '0',
          }
      }
    ]
    
    }); 
    $(this.slider.nativeElement).slick('refresh'); 
    

  }
  /**
   * 
   * Next
   * 
   */
  prev(){ 

    $(this.slider.nativeElement).slick("slickPrev");  

  }
  /**
   * 
   * Next
   * 
   */
  next(){

    $(this.slider.nativeElement).slick("slickNext"); 

  }
   /**
   * 
   * Init
   * 
   */
  ngOnInit(): void {
    
  }
  /**
   * 
   * After View
   * 
   ***/
  ngAfterViewInit(): void {
    this.initSlider();
  }

}

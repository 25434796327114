import { Component,Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'produto-item-slider-two',
  templateUrl: './produto-item-slider-two.component.html',
  styleUrls: ['./produto-item-slider-two.component.scss']
})
export class ProdutoItemSliderTwoComponent implements OnInit {

  @Input("produtos") produtos = [];
  @ViewChild("slider") slider:any; 
  @Input("type") type = 1; 
  
  constructor(){} 

  /**
   * 
   * Next
   * 
   */
   prev(){ 

    this.slider.nativeElement.scrollLeft -= 290;

  }
  /**
   * 
   * Next
   * 
   */
  next(){


    this.slider.nativeElement.scrollLeft += 290;


  }
  /***
   * 
   * Init
   * 
   ***/
  ngOnInit():void{}

}

<div [ngSwitch]="template">
  <section *ngSwitchCase="null" class="default-footer">
    <div class="module module-contact">
      <div class="module-title" *ngIf="data.mostrar_titulo">
          <div class="container">
              <h2>{{data.titulo}}</h2>
              <h3>{{data.subtitulo}}</h3>
          </div>
      </div>
      <div class="module-content">
          <div class="container">
              <div class="row">
                  <div class="col-md-3">
                      <div class="item item-whatsapp box-sombra">
                          <div class="icon">
                              <i class="fa-brands fa-whatsapp"></i>
                          </div>
                          <div class="title">
                              <h4>WhatsApp</h4>
                          </div>
                          <div class="description">
                              <span>Entre em contato via WhatsApp.</span>
                          </div>
                          <div class="call-action" (click)="openWhats()">
                              <button type="button">
                                  <span>Clique Aqui</span>
                              </button>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-3">
                      <div class="item item-whatsapp box-sombra">
                          <div class="icon">
                              <i class="fa fa-phone"></i>
                          </div>
                          <div class="title">
                              <h4>Telefone</h4>
                          </div>
                          <div class="description">
                              <span>Entre em contato por Telefone.</span>
                          </div>
                          <div class="call-action">
                              <button type="button" (click)="openTel()">  
                                  <span>{{data.params.celular}}</span>
                              </button>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-3">
                      <div class="item item-whatsapp box-sombra">
                          <div class="icon">
                              <i class="fa-solid fa-envelope"></i>
                          </div>
                          <div class="title">
                              <h4>E-mail</h4>
                          </div>
                          <div class="description">
                              <span>Entre em contato por E-mail.</span>
                          </div>
                          <div class="call-action">
                              <button type="button" (click)="openEmail()">
                                  <span>{{data.params.email}}</span>
                              </button>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-3" *ngIf="template == null">
                      <div class="item item-whatsapp box-sombra">
                          <div class="icon">
                              <i class="fa-solid fa-message"></i>
                          </div>
                          <div class="title">
                              <h4>Mensagem</h4>
                          </div>
                          <div class="description">
                              <span>Nos envie uma mensagem.</span>
                          </div>
                          <div class="call-action">
                              <button type="button" (click)="toContact()">
                                  <span>Clique Aqui</span>
                              </button>
                          </div>
                      </div>
                  </div> 
                  <div class="col-md-3" *ngIf="template == 'page-contact'">
                      <div class="item item-whatsapp box-sombra">
                          <div class="icon">
                              <i class="fa-solid fa-heart"></i>
                          </div>
                          <div class="title">
                              <h4>Redes Sociais</h4>
                          </div>
                          <div class="list-social">
                              <div class="list-social-item">
                                  <a href="https://www.facebook.com/" target="_blank">
                                      <i class="fa-brands fa-facebook"></i>
                                  </a>
                              </div>
                              <div class="list-social-item">
                                  <a href="https://www.instagram.com/" target="_blank">
                                      <i class="fa-brands fa-instagram"></i>
                                  </a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  </section> 
  <div *ngSwitchCase="'page-contact'" class="page-contact">
    <div class="module module-contact">
        <div class="module-title" *ngIf="data.mostrar_titulo">
            <div class="container">
                <h2>{{data.titulo}}</h2>
                <h3>{{data.subtitulo}}</h3>
            </div>
        </div>
        <div class="module-content">
            <div class="container">
                <div class="row">
                    <div class="col-md-3">
                        <div class="item item-whatsapp box-sombra">
                            <div class="icon">
                                <i class="fa-brands fa-whatsapp"></i>
                            </div>
                            <div class="title">
                                <h4>WhatsApp</h4>
                            </div>
                            <div class="description">
                                <span>Entre em contato via WhatsApp.</span>
                            </div>
                            <div class="call-action" (click)="openWhats()">
                                <button type="button">
                                    <span>Clique Aqui</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="item item-whatsapp box-sombra">
                            <div class="icon">
                                <i class="fa fa-phone"></i>
                            </div>
                            <div class="title">
                                <h4>Telefone</h4>
                            </div>
                            <div class="description">
                                <span>Entre em contato por Telefone.</span>
                            </div>
                            <div class="call-action">
                                <button type="button" (click)="openTel()">  
                                    <span>{{data.params.celular}}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="item item-whatsapp box-sombra">
                            <div class="icon">
                                <i class="fa-solid fa-envelope"></i>
                            </div>
                            <div class="title">
                                <h4>E-mail</h4>
                            </div>
                            <div class="description">
                                <span>Entre em contato por E-mail.</span>
                            </div>
                            <div class="call-action">
                                <button type="button" (click)="openEmail()">
                                    <span>{{data.params.email}}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3" *ngIf="template == null">
                        <div class="item item-whatsapp box-sombra">
                            <div class="icon">
                                <i class="fa-solid fa-message"></i>
                            </div>
                            <div class="title">
                                <h4>Mensagem</h4>
                            </div>
                            <div class="description">
                                <span>Nos envie uma mensagem.</span>
                            </div>
                            <div class="call-action">
                                <button type="button" (click)="openForm()">
                                    <span>Clique Aqui</span>
                                </button>
                            </div>
                        </div>
                    </div> 
                    <div class="col-md-3" *ngIf="template == 'page-contact'">
                        <div class="item item-whatsapp box-sombra">
                            <div class="icon">
                                <i class="fa-solid fa-heart"></i>
                            </div>
                            <div class="title">
                                <h4>Redes Sociais</h4>
                            </div>
                            <div class="list-social">
                                <div class="list-social-item">
                                    <a [href]="data.params.facebook" target="_blank">
                                        <i class="fa-brands fa-facebook"></i>
                                    </a>
                                </div>
                                <div class="list-social-item">
                                    <a [href]="data.params.instagram" target="_blank">
                                        <i class="fa-brands fa-instagram"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  <div *ngSwitchCase="'checkout-pedido'" class="checkout-pedido">
    <a class="content-item" href="tel:{{data.params.celular}}" title="E-mail: {{data.params.celular}}">
      <span class="title">Se preferir, ligue pra nós:</span> 
      <strong class="value">
        <i class="material-icons">call</i>
        {{data.params.celular}}
      </strong>
    </a>
  </div> 
  <div *ngSwitchCase="'rede-social'">
    <div class="rede-social">
        <ul>
            <li>
                <a [href]="data.params.facebook" target="_blank" title="Facebook">
                    <i class="fa-brands fa-square-facebook"></i>
                </a>
            </li>
            <li>
                <a [href]="data.params.instagram" target="_blank" title="Instagram">
                    <i class="fa-brands fa-square-instagram"></i>
                </a>
            </li>
        </ul>
    </div>
  </div>
  <div *ngSwitchCase="'footer'">
        <div class="contact">
            <h3 class="title-h3">Contato</h3>   
            <ul>
                <li>
                    <a href="mailto:{{data.params.email}}">
                        {{data.params.email}}
                    </a>    
                </li>
                <li>
                    <a href="tel:{{data.params.celular}}">
                        {{data.params.celular}}
                    </a>    
                </li>
            </ul>
        </div>
    </div>
</div>  


<a href="javascript::void()" (click)="onClick()">
      <div class="product-item-best-seller {{classCustom}}">
          <div class="capa" *ngIf="produto.foto != null">
              <img [src]='produto.foto' [alt]="produto.nome" width="100%" />
          </div>
          <div class="capa" *ngIf="produto.foto == null">  
            <a [href]="link" (click)="onClick()" itemprop="url" title="Saiba mais sobre: {{produto.nome}}">  
              <img itemprop="image" src="/images/produto/sem-foto/default-capa.jpg" [alt]="produto.nome" width="263px" height="174px" />
            </a> 
          </div>
          <div class="content">
              <h4>{{produto.nome}}</h4>      
              <div class="footer">
                  <div class="box">
                      <div class="content-valor">
                          <div class="valor">
                              <span class="cifrao">R$ </span>
                              <strong>{{produto.valor | currency: " "}}</strong>
                          </div>
                      </div> 
                  </div> 
              </div> 
          </div>    
      </div>   
</a> 
      
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { GtagService } from 'src/app/services/gtag.service';

declare var $:any;

@Component({
  selector: 'modulo-produto-promocao',
  templateUrl: './produto-promocao.component.html',
  styleUrls: ['./produto-promocao.component.scss']
})
export class ProdutoPromocaoComponent implements OnInit {

  @Input("data") data:any           = {};
  @Input("classCustom") classCustom = "";
  @Input("template") template       = null;   
  public produtos                   = []; 
  public categorias                 = [];
  public produtosArray              = new Array();
  @ViewChild("slider") slider:ElementRef; 

  constructor(
    private gtag: GtagService 
  ){ }
    
  /**
   * 
   * Seta os produtos
   * 
   */
  setProdutos(){
    
    this.produtos = this.data.produtos
    this.gtag.eventList(this.produtos,"index");

  }
  /***
   * 
   * Seta os produtos para o Array
   * 
   */
  setProdutosArray(){

    let count         = 0;
    let linha         = [];
    let produtosArray = [];
    let indexArray    = 0;

    for (let index = 0;index < this.produtos.length;index++) {

      if(count <= 4){

        if(typeof(this.produtosArray[indexArray]) == "undefined"){
          this.produtosArray[indexArray] = [this.produtos[index]];
        }else{
          this.produtosArray[indexArray].push(this.produtos[index]);
        }  
        count++;
        
      }else{

        count = 0;
        indexArray++;
        this.produtosArray[indexArray] = [this.produtos[index]];

       
      }
      

        
      
    }
    


  }
  /**
   * 
   * 
   * 
   */
  getProdutoOfArray(produtos,i){


    let out = [];

    if(typeof(produtos[i]) != "undefined"){

      for(i;i < produtos.length;i++) {
        
        out.push(produtos[i]);
        
      }

    }

    return out;

  }
  /***
   * 
   * Inicializa o Slider
   * 
   */
  initSlider(){

    $(this.slider.nativeElement).slick({  
      infinite: true,
      slidesToShow: 4,
      dots: false,
      arrows: false,
      centerMode: false,
      centerPadding: '5px',
      responsive: [{
          breakpoint: 1224,
          settings: {
              slidesToShow: 3, 
          }
      },{
          breakpoint: 1024,
          settings: {
          slidesToShow: 2,
        }
      },
      {
          breakpoint: 768,
          settings: {
              slidesToShow: 1,

          }
      },
      {
          breakpoint: 540,
          settings: {
            slidesToShow: 1,
            centerPadding: '0',
          }
      }
    ]
    
    }); 
    $(this.slider.nativeElement).slick('refresh');  

  }
  /**
   * 
   * Next
   * 
   */
  next(){

    $(this.slider.nativeElement).slick("slickNext"); 

  }
  /**
   * 
   * Next
   * 
   */
  prev(){ 

    $(this.slider.nativeElement).slick("slickPrev");  

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    this.setProdutos();
  }
  /**
   * 
   * Cerrega depois de carregar a VIEW
   * 
   */
  ngAfterViewInit(): void {
    this.initSlider();
  }

}

<div class="faixa">
      <img src="images/template/faixa.svg" alt="Faixa" width="100%" />
  </div>
  <div class="module module-product-news">
      <div class="container">
          <div class="row">
              <div class="col-md-4 center-flex">
                  <div class="title">
                      <h2 class="wow slideInLeft" data-wow-delay="0.7s">{{data.titulo}}</h2>
                      <h3 class="wow slideInUp" data-wow-delay="0.8s">{{data.subtitulo}}</h3>
                  </div>
              </div>
              <div class="col-md-8">
                  <div class="products slider-items" #slider>
                      <div class="slider-item" *ngFor="let p of data.produtos">
                          <produto-item [data]="p"></produto-item>
                      </div> 
                  </div>
                  <div class="navigate-bottom">
                      <div class="navigate-item prev" (click)="prev()">
                          <img width="30px" height="30px" src="images/icons/prev.svg" alt="Prev" />
                      </div>
                      <div class="navigate-item next" (click)="next()">
                          <img width="30px" height="30px" src="images/icons/next.svg" alt="Next" />
                      </div> 
                  </div>
              </div>
          </div>
      </div>
  </div>
  <div class="faixa-bottom">
      <img src="images/template/faixa-2.svg" alt="Faixa" width="100%" />
  </div>
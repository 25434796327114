import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import { BannerSliderTwoComponent } from 'src/app/modulos/banner-slider-two/banner-slider-two.component';


@NgModule({
  declarations: [
    BannerSliderTwoComponent
  ],
  exports: [
    BannerSliderTwoComponent
  ],
  imports: [
    LazyLoadImageModule,
    CommonModule  
  ]
})
export class ModuloBannerSliderModule{}
